import {Controller} from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
    connect() {
        this.swiper = new Swiper(this.element, {
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: {
                delay: 4000, disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination", clickable: true, dynamicBullets: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                }, 992: {
                    slidesPerView: 3,
                },
            },
        });
    }
}