import "./libs/jquery";
import "./libs/bootstrap";
import "@hotwired/turbo-rails"

Turbo.setProgressBarDelay(100);

import './landing-controllers'

$(document).ready(function () {
    $(document).on('click', 'a[href="#"]', function (event) {
        event.preventDefault();
    });

    $(document).on('click', '.navigation-toggle', function (event) {
        event.preventDefault();
        $('body').toggleClass('navigation-open');
    });

    $(document).on('click', '.navigation-mobile a', function (event) {
        $('body').removeClass('navigation-open');
    });

    $(document).on('click', '.faq-title', function (event) {
        event.preventDefault();
        $(this).parents('.faq-item').toggleClass('active');
        $(this).parents('.faq-item').find('.faq-content').slideToggle(300);
    });

});
