// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        $(this.element).on("click", (e) => {
            if ($(e.target).hasClass("disable-loading")) return;

            e.preventDefault();
            e.stopImmediatePropagation();

            this.loadModal();
        });

        if (this.element.dataset.autoLoad == "true") {
            this.loadModal();
        }
    }

    loadModal() {
        var that = this;
        $(".round-card.popup").remove();

        const {url, cssClass, title, content} = this.element.dataset;
        this.createModel(cssClass);

        $("#ajax-modal").on("hidden.bs.modal", function (e) {
            $("#ajax-modal").empty().remove();
        });

        if (url) {
            $.get(url, (data) => {
            })
                .done((html) => {
                    const response = $("<div>").html(html);

                    this.setContent(response.find("#title").html(), response.find("#modal").html());
                })
                .fail((err) => {
                    if (401 == err.status) {
                        this.setContent("", `<div class='col text-center p-5'> <h2>${err.responseText}</h2>
              <p><a href="/users/sign_in?return_to=${location.pathname}" class="btn btn-primary">Login</a></p></div>`);
                    } else {
                        this.setContent("Failed", "<div class='col text-center p-5'>Sorry, we were unable to load this page. Please try again.</div>");
                    }
                });
        } else {
            this.setContent(title, `<div class="modal-body">${content}</div>`);
        }
    }

    setContent(title, body) {
        if (!!title) {
            this.dialog.find("#modal-title").html(title);
        } else {
            this.dialog.find("#modal-title").remove();
        }

        this.dialog
            .find("#modal-body")
            .html(body || "<div class='col text-center p-5'>Something went wrong, please try again</div>");
    }

    createModel(classes) {
        if ($("#ajax-modal").length > 0) {
            $("#ajax-modal").remove();
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
        }

        const bodyClasses = document.body.className;
        let modal = `
        <div class="modal" tabindex="-1" role="dialog" id="ajax-modal" data-body-classes="${bodyClasses}">
          <div class='modal-dialog ${classes ? classes : ""}' role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modal-title">Loading</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">

                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div id="modal-body">
              <div class="modal-body">
                <p class="text-center"><i class="fal fa-spinner fa-spin my-3"></i> Loading</p>
              </div>
              <div class="modal-footer" id="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>`;

        this.dialog = $(modal);
        this.dialog.appendTo("body");

        $("#ajax-modal").on("hidden.bs.modal", function (e) {
            document.body.className = $("#ajax-modal").data("bodyClasses");
            $("#ajax-modal").empty().remove();
        });

        this.dialog.modal({backdrop: "static", focus: false});
    }
}